/* body {
	margin: 0;
	font-family: 'CoFoSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
 */

@font-face {
	font-family: 'CoFoSans';
	font-weight: 100;
	src: local('CoFoSans'), url(@assets/fonts/CoFoSans/CoFoSans-Thin.otf) format('opentype');
}


@font-face {
	font-family: 'CoFoSans';
	font-weight: 300;
	src: local('CoFoSans'), url(@assets/fonts/CoFoSans/CoFoSans-Light.otf) format('opentype');
}

@font-face {
	font-family: 'CoFoSans';
	font-weight: 400;
	src: local('CoFoSans'), url(@assets/fonts/CoFoSans/CoFoSans-Regular.otf) format('opentype');
}

@font-face {
	font-family: 'CoFoSans';
	font-weight: 500;
	src: local('CoFoSans'), url(@assets/fonts/CoFoSans/CoFoSans-Medium.otf) format('opentype');
}

@font-face {
	font-family: 'CoFoSans';
	font-weight: 600;
	src: local('CoFoSans'), url(@assets/fonts/CoFoSans/CoFoSans-Bold.otf) format('opentype');
}

@font-face {
	font-family: 'CoFoSans';
	font-weight: 700;
	src: local('CoFoSans'), url(@assets/fonts/CoFoSans/CoFoSans-Bold.otf) format('opentype');
}

@font-face {
	font-family: 'CoFoSans';
	font-weight: 800;
	src: local('CoFoSans'), url(@assets/fonts/CoFoSans/CoFoSans-Black.otf) format('opentype');
}

@font-face {
	font-family: 'CoFoSans';
	font-weight: 900;
	src: local('CoFoSans'), url(@assets/fonts/CoFoSans/CoFoSans-Black.otf) format('opentype');
}



:root {
	--toastify-color-info: #0A348F;
	--toastify-color-success: #40927E;
	--toastify-color-warning: #EFB930;
	--toastify-color-error: #EF3030;
}

.Toastify__toast {
	border-radius: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 0s 600000s, color 0s 600000s;
}

.user-history-list>div {
	width: 95%;
	margin-left: auto;
	margin-right: auto;
}